<template>
<div class="my-2 py-2">
      <b-row class="align-items-center tournament-list">
            <b-col cols="auto">
                  <b-img class="icon" :src="logo"></b-img>
            </b-col>
            <b-col>
                 <h3>{{tournament.name}}</h3>
            </b-col>
      </b-row>
      <b-row class="font-weight-bold mt-2 d-none d-md-flex border-bottom font-large">
            <b-col cols="2">
                  Grupp
            </b-col>
            <b-col cols="2">
                  Datum
            </b-col>
            <b-col cols="3">
                  Hemmalag
            </b-col>
            <b-col cols="2" class="text-center">
                  Resultat          
            </b-col>
            <b-col cols="3" class="text-right">
                  Bortalag
            </b-col>

      </b-row>
      <TeamGameRow v-for="(game, index) in games" :key="index" :game="game"></TeamGameRow>
      <hr/>
</div>
</template>

<script>
const TeamGameRow = () => import("@/components/team/TeamGameRow");

export default {
      name: "TeamTournamentRow",
      props: ["games"],
      components: {
            TeamGameRow
      },
      computed: {
            tournament() {
                  return this.games[0].tournament;
            },
            logo() {
                  return this.$images.tournamentImage(this.tournament.uuid, this.tournament.imgversion);
            }
      },
      methods: {},
      mounted() {},
};
</script>
